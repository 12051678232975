html,
body,
#root {
	width: 100%;
	height: 100%;
}

html {
	font: 400 16px/20px 'Halvar Mittel';
}

body {
	color: #fff;
	background: #000;
}
